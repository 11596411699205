<template>
  <div>
    <!-- <div style="width: 100%; height: 50px; line-height: 50px; text-align: left;">SLA Configuration</div> -->
    <el-select
      v-model="customer"
      size="mini"
      placeholder="Please choose customer"
      style="float: left;"
      @change="handleChange"
    >
      <el-option
        v-for="item in customers"
        :key="item.name"
        :label="item.name"
        :value="item.name"
      >
      </el-option>
    </el-select>
    <el-table
      :data="slas"
      stripe
      size="mini"
      border
      style="width: 100%; float: left; margin-top: 20px;"
    >
      <el-table-column prop="priority" label="Priority">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.priority"
            :disabled="scope.row.disabled"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="responseTime" label="Response Time (Hours)">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.responseTime"
            :disabled="scope.row.disabled"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="resolveTime" label="Resolve Time (Hours)">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.resolveTime"
            :disabled="scope.row.disabled"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Action" width="150px">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.disabled"
            type="primary"
            icon="el-icon-edit"
            circle
            size="mini"
            plain
            @click="edit(scope)"
          ></el-button>
          <el-button
            v-if="!scope.row.disabled"
            type="primary"
            icon="el-icon-check"
            circle
            size="mini"
            plain
            @click="saveItem(scope)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
            plain
            @click="remove(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button
      @click="addNewItem"
      type="primary"
      size="mini"
      plain
      style="float: left; margin-top: 20px;"
      >Add New Item</el-button
    >
  </div>
</template>
<script>
import http from "../utils/http";
export default {
  data() {
    return {
      customer: "",
      customers: [],
      slas: [],
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    handleChange(customer) {
      this.getSlaByCustomer(customer);
    },
    edit(scope) {
      scope.row.disabled = false;
    },
    remove(scope) {
      if (scope.row.id == null || scope.row.id == "") {
        this.slas.splice(scope.$index, 1);
      } else {
        http.post("/sla/deleteSla", { id: scope.row.id }).then((res) => {
          if (res.data.status === 1) {
            this.slas.splice(scope.$index, 1);
            this.$message({
              message: "deleted successfully!",
              type: "success",
            });
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    saveItem(scope) {
      if (scope.row.id == null || scope.row._id == "") {
        http
          .post("/sla/addSla", {
            customer: this.customer,
            priority: scope.row.priority,
            responseTime: scope.row.responseTime,
            resolveTime: scope.row.resolveTime,
          })
          .then((res) => {
            if (res.data.status === 1) {
              this.$message({
                message: "SLA was saved successfully",
                type: "success",
              });
              scope.row.disabled = true;
            } else {
              this.$message.error(res.data.message);
            }
          });
      } else {
        http
          .put("/sla/updateSla", {
            id: scope.row.id,
            customer: this.customer,
            priority: scope.row.priority,
            responseTime: scope.row.responseTime,
            resolveTime: scope.row.resolveTime,
          })
          .then((res) => {
            if (res.data.status === 1) {
              this.$message({
                message: "SLA was saved successfully",
                type: "success",
              });
              scope.row.disabled = true;
            } else {
              this.$message.error(res.data.message);
            }
          });
      }
    },
    addNewItem() {
      this.slas.push({
        customer: this.customer,
        priority: "",
        responseTime: "",
        resolveTime: "",
        disabled: false,
      });
    },
    getCustomers() {
      http.get("/customer/getCustomer").then((res) => {
        if (res.data.status === 1) {
          this.customers.splice(0, this.customers.length);
          res.data.data.forEach((value) => {
            this.customers.push({
              name: value.name,
              description: value.description,
            });
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getSlaByCustomer(customer) {
      http.post("/sla", { customer: customer }).then((res) => {
        if (res.data.status === 1) {
          this.slas.splice(0, this.slas.length);
          res.data.data.forEach((value) => {
            this.slas.push({
              customer: value.customer,
              priority: value.priority,
              responseTime: value.responseTime,
              resolveTime: value.resolveTime,
              disabled: true,
              id: value._id,
            });
          });
          this.slas.sort((value1, value2) => {
            return value1.priority.toLowerCase() > value2.priority.toLowerCase()
              ? 1
              : -1;
          });
        }
      });
    },
  },
};
</script>
<style scoped></style>
